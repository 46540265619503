import React from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { ReactComponent as MyIcon } from '../assets/ak.svg';


const Contact = () => {
    return (
        <div
            name="contact"
            className="w-full min-h-screen bg-[#b8bab9] text-gray-100 flex justify-center items-center flex-col lg:flex-row mx-auto"
        >
            <div className=" w-11/12 lg:w-7/12 lg:h-[55rem] justify-between flex flex-col gap-3">
                <div className="mt-60">
                    <h1 className=" text-2xl lg:text-5xl  font-semibold">INTERESTED IN WORKING TOGETHER?</h1>
                    <p className="flex flex-col lg:flex-row gap-2 md:gap-6">
                        <h2 className=" text-xl lg:text-4xl ml-2">aakashpatel315@gmail.com</h2>
                        <a href="https://mail.google.com/mail/?view=cm&fs=1&to=aakashpatel315@gmail.com"
                            className="group text-white w-fit px-5 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-600 to-blue-500 cursor-pointer"
                        >Send Mail
                            <span className="group-hover:rotate-90 duration-300">
                                <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
                            </span>
                        </a>
                    </p>
                </div>
                {/* <MyIcon className="w-0 h-0 lg:w-60 lg:h-60" /> */}

            </div>
            <img src="/lucky-cat.jpg" draggable="false" alt="lucky-cat" className="w-64 lg:w-auto" />
            <MyIcon className="w-32 h-32 lg:hidden" />

        </div>

    );
};

export default Contact;
