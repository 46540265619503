import React from "react";
import {
    motion
} from "framer-motion"

const About = () => {
    return (
        <div
            name="about"
            className="w-full h-screen bg-[#1d1d1d] text-white"
        >
            <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
                <motion.div className="pb-8" initial={{ x: -100, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} transition={{ duration: 1.3, type: 'spring', delay: 0.3 }} viewport={{ once: true }}>
                    <p className="text-4xl font-bold inline border-b-4 border-gray-500">
                        About
                    </p>
                </motion.div>

                <motion.p className="text-md lg:text-xl mt-20" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.5, duration: 1.2 }} viewport={{ once: true, amount: 0.5 }}>
                    "I'm a full-stack developer with expertise in Express, Node.js, React, Tailwind CSS, and Next.js. I specialize in building both the backend and frontend of web
                    applications. Using Express and Node.js, I create robust APIs and backend functionality, while leveraging React, Next.js, and Tailwind CSS to develop responsive, user-friendly interfaces. My focus is on delivering scalable,
                    high-performance applications by seamlessly integrating these technologies throughout the entire development lifecycle."
                </motion.p>

                <br />

                <motion.p className="text-md lg:text-xl" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.5, duration: 1.2 }} viewport={{ once: true, amount: 0.5 }}>
                    I am familiar with tools like git and github and can work efficiently in a team. I have collaborated in various projects in both
                    frontend and backend.
                </motion.p>
            </div>
        </div>
    );
};

export default About;
