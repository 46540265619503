import React, { useState, useEffect } from 'react';
import { IoHeadset } from "react-icons/io5";

const AllLetters = ['A','आ','诶','அ','あ','a','ㅏ',<IoHeadset />]

const Loading = () => {
    const [count, setCount] = useState(0);
    const [loadingComplete, setLoadingComplete] = useState(false); // Track loading completion
    const [fadeOut, setFadeOut] = useState(false); // Track the fade-out animation state

    useEffect(() => {
        const timer = setInterval(() => {
            setCount(prevCount => (prevCount + 1) % 8);
        }, 250);

        // Set loading complete after 5 seconds (for example)
        const loadingTimeout = setTimeout(() => {
            setFadeOut(true); // Trigger fade out animation
            setTimeout(() => setLoadingComplete(true), 1000); // After animation, complete loading
        }, 3300); // Adjust this time to the duration of the loading

        return () => {
            clearInterval(timer);
            clearTimeout(loadingTimeout);
        };
    }, []);

    if (loadingComplete) {
        return null; // Remove loading component from the DOM after it's complete
    }

    return (
        <div className={`h-screen w-screen flex justify-center font-bold items-center text-9xl bg-[#f2b980] transition-opacity duration-1000 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}>
            <h2 className='text-white'>{AllLetters[count]}</h2>
        </div>
    );
};

export default Loading;
