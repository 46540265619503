import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
// import { GiHamburgerMenu } from "react-icons/gi";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import { ReactComponent as MyIcon } from '../assets/AP.svg';


const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(255, 255, 255, 0)"
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "rgba(255, 255, 255, 1)"
  }
};

const NavBar = () => {
  const [nav, setNav] = useState(false);

  const links = [
    {
      id: 1,
      link: "home",
    },
    {
      id: 2,
      link: "about",
    },
    // {
    //   id: 3,
    //   link: "projects",
    // },
    {
      id: 4,
      link: "experience",
    },
    {
      id: 5,
      link: "contact",
    },
  ];

  return (
    <div className="flex justify-between items-center w-full h-24 pl-2 md:pl-20 text-gray-300 fixed z-50 bg-[#1d1d1d]">

      <div className="flex flex-row justify-center items-center">
        <div className="flex flex-row gap-2">
          <svg
            width="100px"
            height="100px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="107.33433837890627 -68.79999999999998 285.33132324218747 287.59999999999997"
            preserveAspectRatio="xMidYMid"
            className="hoverable_svg"
          >
            <defs>
              <filter id="editing-jagged" x="-100%" y="-100%" width="300%" height="300%">
                <feTurbulence result="turb" baseFrequency="0.23" numOctaves="1" seed="1"></feTurbulence>
                <feDisplacementMap in="SourceGraphic" in2="turb" scale="13.5"></feDisplacementMap>
              </filter>
            </defs>
            <g filter="url(#editing-jagged)">
              <g transform="translate(154.35500979423523, 138.79000282287598)">
                <path
                  d="M21.29 0L21.29-5.46L88.27-5.46L49.69-111.57L9.10 0L3.28 0L49.69-127.58L96.10 0L21.29 0ZM172.72-66.98L162.53-61.15L159.80-65.88L169.99-71.71L169.99-71.71Q182.55-78.62 182.55-93.73L182.55-93.73L182.55-93.73Q182.55-121.94 139.59-121.94L139.59-121.94L122.85-121.94L122.85 0L117.39 0L117.39-127.40L139.59-127.40L139.59-127.40Q163.62-127.40 175.81-118.57L175.81-118.57L175.81-118.57Q188.01-109.75 188.01-93.73L188.01-93.73L188.01-93.73Q188.01-75.53 172.72-66.98L172.72-66.98Z"
                  stroke="white"       // Set the stroke color (line color)
                  strokeWidth="5"      // Set the stroke width (line thickness)
                  fill="white"          // Optional: remove the fill color if you only want the outline
                />
              </g>
            </g>
          </svg>

        </div>
      </div>
      <div className=" h-full flex items-center justify-center">
        <div className=" w-20 h-full bg-stone-200 -m-[1px]">
          <div className="h-full w-full bg-[#1d1d1d]  rounded-tr-[62px]">
          </div>
        </div>
        <div className=" h-full flex justify-end items-center px-20 bg-stone-200 rounded-bl-[63px] ml-[-3px]">
          <ul className="hidden md:flex gap-4 ">
            {links.map(({ id, link }) => (
              <li
                key={id}
                className="px-6 cursor-pointer capitalize text-black hover:scale-105 text-xl duration-200 "
              >
                <Link to={link} smooth duration={500}>
                  {link}
                </Link>
              </li>
            ))}
          </ul>
          <div
            onClick={() => setNav(!nav)}
            className="cursor-pointer pr-4 text-gray-500 md:hidden"
          >
            {nav ? <FaBars /> : <FaTimes size={30} style={{ color: "black" }} />}
          </div>

          {nav && (
            <ul className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-800 text-white">
              {links.map(({ id, link }) => (
                <li
                  key={id}
                  className="px-4 cursor-pointer capitalize py-6 text-4xl"
                >
                  <Link
                    onClick={() => setNav(!nav)}
                    to={link}
                    smooth
                    duration={500}
                  >
                    {link}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>


    </div>
  );
};

export default NavBar;
