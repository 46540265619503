import React from "react";
import TagSphere from "./TagSphere";
import html from "../assets/html.png";
import css from "../assets/css.png";
import javascript from "../assets/javascript.png";
import reactImage from "../assets/react.png";
import nextjs from "../assets/nextjs.png";
import graphql from "../assets/graphql.png";
import github from "../assets/github.png";
import tailwind from "../assets/tailwind.png";
import {
  motion
} from "framer-motion"

const Experience = () => {
  const techs = [
    {
      id: 1,
      src: html,
      title: "HTML",
      style: "shadow-orange-500",
    },
    {
      id: 2,
      src: css,
      title: "CSS",
      style: "shadow-blue-500",
    },
    {
      id: 3,
      src: javascript,
      title: "JavaScript",
      style: "shadow-yellow-500",
    },
    {
      id: 4,
      src: reactImage,
      title: "React",
      style: "shadow-blue-600",
    },
    {
      id: 5,
      src: tailwind,
      title: "Tailwind",
      style: "shadow-sky-400",
    },
    {
      id: 6,
      src: nextjs,
      title: "Next JS",
      style: "shadow-white",
    },
    {
      id: 7,
      src: graphql,
      title: "GraphQL",
      style: "shadow-pink-400",
    },
    {
      id: 8,
      src: github,
      title: "GitHub",
      style: "shadow-gray-400",
    },
  ];

  const skills = [
    "HTML",
    "CSS",
    "MySql",
    "JavaScript",
    "TypeScript",
    "TailwindCSS",
    "SASS",
    "Express",
    "MongoDB",
    "SQL",
    "React",
    "Angular",
    "Node.js",
    "AWS",
    "Postman",
    "ES6",
    "C/C++",
    "Webpack",
    "Git",
    "GitHub",
    "OOPS",
    "RESTful API"
  ]
  const options = {
    radius: window.innerWidth <= 768 ? 150 : 200, // Adjust radius based on screen width
    maxSpeed: 1
  }

  return (
    <div
      name="experience"
      className="bg-[#1d1d1d] max-w-screen min-h-screen"
    >
      <div className="p-4 flex flex-col justify-start h-full text-white gap-20">
        <div className="mx-auto">
          <p className="text-4xl font-bold border-b-4 p-2 inline">
            Experience
          </p>

        </div>

        <div className=" flex flex-col lg:flex-row mx-auto lg:gap-24">

          <div className=" flex flex-row gap-4">
            <div className="flex flex-col items-start">
              <div class="flex flex-col items-center">
                <motion.div className="vline"
                  initial={{ height: '0rem' }}
                  whileInView={{ height: '5rem' }}
                  transition={{ duration: 1, ease: "linear" }} viewport={{ once: true }}>
                </motion.div>
                <div class="circle circle-black"></div>
                <motion.div className="vline2"
                  initial={{ height: '0rem' }}
                  whileInView={{ delay: 2, height: '10rem' }}
                  transition={{ duration: 1, ease: "linear" }}
                  viewport={{ once: true }}>
                </motion.div>
                <div class="circle circle-black"></div>
              </div>
            </div>
            <div>
              <div className=" flex flex-row items-end justify-start mt-[5rem]">
                <a href="https://therightdoctors.com">
                  <motion.h3 initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ delay: 0.5, duration: 1.2 }}
                    viewport={{ once: true, amount: 0.5 }}
                    className=" text-lg lg:text-2xl hover:text-cyan-600">
                    TheRightDoctors | Full-Stack Developer
                  </motion.h3>
                </a>
              </div>
              <div className=" flex flex-row items-end justify-center mt-[8rem]">
                <a href="https://team-srm-hackathon.vercel.app/">
                  <motion.h3 initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ delay: 0.7, duration: 1.2 }}
                    viewport={{ once: true, amount: 0.5 }}
                    className=" text-lg lg:text-2xl hover:text-cyan-600">
                    Team SRM Hackathon | Full-Stack Developer 
                  </motion.h3>
                </a>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-24 ml-2">
            <TagSphere texts={skills} options={options} />
          </div>

        </div>
      </div>
    </div>
  );
};

export default Experience;
